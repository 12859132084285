<template>
    <div class="config">
        <div>收货地址</div>
        <div style="overflow: hidden;overflow-y: scroll;height: 95%;">
            <div @click="add" class="addbox">
                <img src="../assets/images/add.png">
                <div>添加新地址</div>
            </div>
            <div class="content-box" ref="list">
                <div v-for="(item,index) in list" :key="index" class="listbox">
                    <div class="flex flex-child-center flex-justify-content-between listbox-line">
                        <div class="listbox-line-text">
                            <span>收件人</span>
                            {{item.connect_name}}
                            <span class="moren" v-if="item.is_default == 1">默认地址</span>
                        </div>
                        <img src="../assets/images/del.png" @click="del(item)">
                    </div>
                    <div class="listbox-line">
                        <div class="listbox-line-text"><span>联系电话</span>{{item.connect_mobile}}</div>
                    </div>
                    <div class="listbox-line">
                        <div class="listbox-line-text"><span>所在地区</span>{{item.province_name}}{{item.city_name}}{{item.area_name}}</div>
                    </div>
                    <div class="flex flex-child-center flex-justify-content-between listbox-line no-margin-bottom">
                        <div class="listbox-line-text"><span>详细地址</span>{{item.detail_address}}</div>
                        <div class="flex listbox-line-click">
                            <div v-if="item.is_default == 0"  @click="moren(item)">设为默认</div>
                            <div style="margin-left: 0.3rem;" @click="edit(item)">编辑</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--弹出-->
        <el-dialog custom-class="dialog"
                   :title="dialogTitle"
                   :visible.sync="dialogFormVisible"
                   :destroy-on-close="true"
                   ref="dialog"
                   @close="onDialogClose"
                   :close-on-click-modal="false"
                   width="28vw" top="20vh"
        >
            <div class="dialog_con">
                <!--间值设置-->
                <div class="tan_body">
                    <el-form  ref="form" label-width="1.2rem" style="max-height: unset;" :model="form" :rules="rules">
                        <el-form-item label="所在地区" prop="cityValue">
                            <el-cascader
                                :options="cityOptions"
                                v-model="form.cityValue"
                                @change="cityChange"
                            >
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="收件人" prop="connect_name">
                            <el-input v-model="form.connect_name" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="connect_mobile">
                            <el-input v-model="form.connect_mobile" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="detail_address">
                            <el-input type="textarea" v-model="form.detail_address" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <el-button type="primary" round @click="buttonSubmit('form')">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "DeliveryAddress",
    data() {
        return {
            list:[],
            dialogFormVisible:false,
            form:{
                cityValue: [], // 城市代码
                connect_name:'',
                connect_mobile:'',
                detail_address:'',
            },
            // 所在省市
            cityOptions: [],
            rules: {
                connect_name: [
                    {required: true, message: '请填写收件人姓名', trigger: 'blur'}
                ],
                connect_mobile: [
                    {required: true, message: '请填写联系电话', trigger: 'blur'}
                ],
                cityValue: [
                    {required: true, message: '请选择所在地区', trigger: 'blur'}
                ],
                detail_address: [
                    {required: true, message: '请填写详细地址', trigger: 'blur'}
                ],
            },
            dialogTitle:'新增收件人信息',
            editId:'',
            is_default:0
        }
    },
    mounted() {
        this.initData()
        this.onDetail();
    },
    created() {

    },
    watch: {
    },
    methods: {
        /**
         * 地址接口
         * */
        initData () {
            this.$axios.post('api/kuerp/order/address/area', {}).then(res => {
                this.cityOptions = res.data.data
            })
        },
        /**
         * 所在地区点击
         * */
        cityChange (value) {
            console.log('value',value)
            console.log('cityValue',this.cityValue)
        },
        /**
         * 新增弹框关闭
         * */
        onDialogClose() {
            this.dialogFormVisible = false
        },
        /**
         * 添加收货地址弹框
         * */
        add() {
            this.dialogFormVisible = true
            this.qingkong()
        },
        /**
         * 清空弹框数据
         * */
        qingkong() {
            this.connect_name = ''
            this.connect_mobile = ''
            this.detail_address = ''
            this.cityValue = []
            this.dialogTitle = '新增收件人信息'
            this.editId = ''
            this.is_default = 0
        },
        /**
         * 设为默认收货地址
         * */
        moren(item) {
            this.$confirm('是否设置为默认地址', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/kuerp/order/address/editdetail', this.$qs.stringify({
                    id: item.id,
                    connect_name:item.connect_name,
                    connect_mobile:item.connect_mobile,
                    province:item.province,
                    city:item.city,
                    area:item.area,
                    detail_address:item.detail_address,
                    is_default:1,
                })).then(res => {
                    this.onDetail();
                })
            }).catch(() => {
                // this.$message({
                //     type    : 'info',
                //     message : '已取消删除'
                // });
            });
        },
        /**
         * 删除收货地址
         * */
        del(item) {
            this.$confirm('确定要删除该收货地址吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/kuerp/order/address/deldetail', this.$qs.stringify({id: item.id})).then(res => {
                    this.onDetail();
                })
            }).catch(() => {
                // this.$message({
                //     type    : 'info',
                //     message : '已取消删除'
                // });
            });
        },
        /**
         * 编辑收货地址
         * */
        edit(item) {
            this.dialogFormVisible = true
            this.dialogTitle = '编辑收件人信息'
            this.form.connect_name = item.connect_name
            this.form.connect_mobile = item.connect_mobile
            this.form.detail_address = item.detail_address
            this.form.cityValue[0] = item.province
            this.form.cityValue[1] = item.city
            this.form.cityValue[2] = item.area
            this.editId = item.id
            this.is_default = item.is_default
        },
        /**
         * 添加收货保存
         * */
        buttonSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.editId =='') {
                        this.$axios.post('api/kuerp/order/address/adddetail', {
                            connect_name:this.form.connect_name,
                            connect_mobile:this.form.connect_mobile,
                            province:this.form.cityValue[0],
                            city:this.form.cityValue[1],
                            area:this.form.cityValue[2],
                            detail_address:this.form.detail_address,
                            is_default:0
                        }).then(res => {
                            console.log('res',res)
                            if (res.data.code == 200) {
                                this.onDetail();
                                this.dialogFormVisible = false
                                this.qingkong()
                            }
                        })
                    } else {
                        this.$axios.post('api/kuerp/order/address/editdetail', {
                            id:this.editId,
                            connect_name:this.form.connect_name,
                            connect_mobile:this.form.connect_mobile,
                            province:this.form.cityValue[0],
                            city:this.form.cityValue[1],
                            area:this.form.cityValue[2],
                            detail_address:this.form.detail_address,
                            is_default:this.is_default
                        }).then(res => {
                            console.log('res',res)
                            if (res.data.code == 200) {
                                this.onDetail();
                                this.dialogFormVisible = false
                                this.qingkong()
                            }
                        })
                    }

                } else {
                    return false;
                }
            });

        },
        onDetail() {
            this.$axios.post('api/kuerp/order/address/getlist', {}).then(res => {
                this.list = res.data.data.list;
                console.log('this.list',this.list)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.content-box {
    //height: 80%;

}
::v-deep {
    .el-dialog__header {
        padding: 0;
        text-align: center;
        height: 1rem;
    }
}
.listbox {
    padding: 0.2rem;
    border-radius: 0.04rem;
    border: 0.01rem solid #CDCDCD;
    font-size: 0.12rem;
    font-weight: 500;
    margin-bottom: 0.1rem;
    .listbox-line {
        margin-bottom: 0.1rem;
        img {
            width: 0.12rem;
            height: 0.12rem;
        }
        .listbox-line-text {
            display: flex;
            align-items: center;
            .moren {
                width: 0.69rem;
                height: 0.22rem;
                border-radius: 0.02rem;
                border: 0.01rem solid #4B83F0;
                font-size: 0.12rem;
                font-weight: 400;
                color: #4B83F0;
                line-height: 0.22rem;
                text-align: center;
                margin-left: 0.05rem;
            }
        }
        .listbox-line-click {
            font-weight: 400;
            color: #4B83F0;
        }
        span {
            display: block;
            width: 0.68rem;
            text-align: right;
            font-size: 0.12rem;
            font-weight: 400;
            color: #666666;
            margin-right: 0.1rem;
            text-align-last: justify;
        }
    }
}
.config {
    height: 100%;
    padding: 0.24rem 0 0.3rem 0.28rem;
    position: relative;
    ::v-deep {
        .el-textarea__inner {
            width: 3.44rem;
            font-size: 0.14rem;
            font-weight: 400;
            min-height: 0.94rem;
        }
        .el-input__inner {
            width: 3.44rem;
            font-size: 0.14rem;
            font-weight: 400;
        }
        .el-form-item__error {
            color: #CF7AE2;
        }
        .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus {
            border-color:#DCDFE6;
        }
        .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
            content: '';
        }
    }
    .dialog-footer {
        font-size: 0.16rem;
        font-weight: 600;
        color: #F9F4E4;
        text-align: center;
        ::v-deep {
            .el-button.is-round {
                width: 3.44rem;
                height: 0.55rem;
                background: #4B83F0;
                border-radius: 0.04rem!important;
            }
        }
    }
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-right: 0.28rem;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }
    .addbox {
        width: 2.05rem;
        background: #F0F5FF;
        border-radius: 0.04rem;
        border: 0.01rem solid #4B83F0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.19rem 0;
        margin: 0.16rem 0 0.1rem 0;
        img {
            width: 0.16rem;
            height: 0.16rem;
        }
        div {
            font-size: 0.16rem;
            font-weight: 500;
            color: #333333;
            margin-left: 0.19rem;
        }
    }
}
</style>
